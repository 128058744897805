import React, { Fragment, useEffect, useState, useContext } from 'react'
import styled from 'styled-components/macro'
import * as Yup from 'yup'
import { Formik, Field, Form } from 'formik'
import { useCustomNav } from 'utils/customNavigate'
import Layout from 'components/Layout/Layout'
import SEO from 'components/SEO/SEO'
import Text from 'components/Text/Text.styles'
import {
  Button,
  BackButton,
  DoubleButtons,
  ComponentWrapper,
  Row,
  Column,
  InfoIcon,
  SectionTitle,
  StokrLoader,
  TextLink,
  CryptoAddress,
} from '@stokr/components-library'
import { FormError } from 'components/Form/Form'
import ButtonContainer from 'components/ButtonContainer/ButtonContainer.styles'
import AgreementItem from 'components/AgreementItem/AgreementItem'
import InfoPanel from 'components/InfoPanel/InfoPanel'
import fixDecimals from 'utils/fix-decimals'
import setRedirectCookie from 'utils/set-redirect-cookie'
import * as urls from 'constants/paths'
import { OrderContext } from 'context/Order/OrderContext'
import { UserContext } from 'context/User/UserContext'
import { ProjectContext } from 'context/Project/ProjectContext'
import { AuthProvider } from '@stokr/components-library'

const ConfirmContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  flex-direction: column;

  ${Button} {
    max-width: 500px;
  }

  @media screen and (max-width: 767px) {
    ${Button} {
      max-width: 400px;
    }
  }
`
const Caption = styled.div`
  font-size: 11px;
  font-weight: 300;
  letter-spacing: 0.4px;
  font-style: italic;
  width: 50%;
  text-align: center;
  max-width: 300px;
  margin: -3px 0px 3px 0px;

  ${(props) =>
    props.center &&
    `
    width:100%;
    max-width:none;
    `}
`

export const SectionTitlePageText = (project) => (
  <>
    {project.name === 'techforgood' ? (
      'KPMG For charity'
    ) : (
      <> Invest in {project.name}</>
    )}
  </>
)

const SelectAddress = () => {
  const [shouldRenderPage, setShouldRenderPage] = useState(false)
  let { initializeOrder } = useContext(OrderContext)
  let { project } = useContext(ProjectContext)
  const navigator = useCustomNav()
  let {
    user = {
      investor_eth_addresses: [],
    },
    setSelectedCurrency,
    setSelectedAddress,
  } = useContext(UserContext)
  const [selectedCurrencyState, setSelectedCurrencyState] = useState()
  const [checkedAddressRadio, setCheckedAddressRadio] = useState()
  const [selectedCryptoAddress, setSelectedCryptoAddress] = useState()
  const [isAgreementRequired, setIsAgreementRequired] = useState()

  const { acceptedCurrencies = {} } = project

  useEffect(() => {
    //Clear previously selected address
    setSelectedAddress()

    // Clear any previous order
    initializeOrder()

    // Ready to render the page
    setShouldRenderPage(true)

    // Auto selects the address in case only 1 option is available
    if (user.wallets.length === 1) {
      setSelectedCryptoAddress(user.wallets[0])
      setCheckedAddressRadio('cryptoAddress0')
    }

    //auto select currency in case only 1 option is available
    if (
      acceptedCurrencies.internalReference.length === 1 &&
      !selectedCurrencyState
    ) {
      const currency = acceptedCurrencies.internalReference[0]
      setSelectedCurrencyState(currency)
      setSelectedCurrency(currency)
    }
  }, [])

  useEffect(() => {
    setIsAgreementRequired(user.agreementRequired)
    if (user.selectedAddress) {
      navigator.navigate(urls.ORDER_TOKENS)
    }
  }, [user])

  const validationSchema = Yup.object().shape({
    currencySelected: Yup.string().required('You need to choose currency'),
    cryptoAddress:
      !checkedAddressRadio &&
      Yup.string().required('You need to choose address'),
  })

  const renderAddressSelectionMessage = () => {
    const oneAddressMessage = `The following address will be used to receive your ${project.tokenSymbol}.`
    const multipleAddressesMessage = `Now, select an ${project.token_issuance_type} address where you want to receive your ${project.tokenSymbol}.`

    return user.wallets.length === 1
      ? oneAddressMessage
      : multipleAddressesMessage
  }

  return shouldRenderPage ? (
    <Layout
      progress={{
        items: [
          { id: 'select-address', handleClick: () => {} },
          { id: 'order-tokens', handleClick: () => {} },
          { id: 'confirm', handleClick: () => {} },
        ],
        doneIndex: -1,
        activeIndex: 0,
      }}
      footerColor="red"
    >
      <SEO
        title={`Invest in ${project.name}`}
        keywords={['Investing', 'STOKR', 'tokens']}
      />
      <Row main>
        <Column part={8}>
          <ComponentWrapper>
            <BackButton href={`https://stokr-staging.de/${project.name}`}>
              BACK TO INVESTMENT OPPORTUNITY
            </BackButton>
          </ComponentWrapper>

          <ComponentWrapper noPadding>
            <InfoPanel />
          </ComponentWrapper>

          <ComponentWrapper noPadding>
            <Row>
              <Column part={16}>
                <ComponentWrapper borderBottom>
                  <ComponentWrapper noPaddingHorizontal noPaddingTop>
                    <SectionTitle>{project.title}</SectionTitle>
                  </ComponentWrapper>
                  <Text>
                    <p>{project.description}</p>
                  </Text>
                </ComponentWrapper>
              </Column>
            </Row>
          </ComponentWrapper>
        </Column>

        <Column part={8} withBorder>
          <ComponentWrapper>
            <SectionTitle>{SectionTitlePageText(project)}</SectionTitle>
          </ComponentWrapper>

          <ComponentWrapper noPaddingTop>
            <Text>
              <h3>SELECT PAYMENT METHOD AND TOKEN RECEVING ADDRESS</h3>
              <p>
                Please select the way you want to pay for {project.tokenSymbol}{' '}
                tokens.
              </p>
            </Text>
          </ComponentWrapper>

          <Formik
            initialValues={{
              currencySelected: '',
              agreementEther2: false,
              cryptoAddress: '',
            }}
            validationSchema={validationSchema}
            onSubmit={async (e) => {
              setSelectedAddress(selectedCryptoAddress)
            }}
          >
            {({ setFieldValue, values, errors }) => {
              let isFormValid
              const hasErrors = Object.keys(errors).length > 0

              if (isAgreementRequired && !values.agreementEther2) {
                isFormValid = false
              } else if (hasErrors || !user.currencyName) {
                isFormValid = false
              } else if (selectedCurrencyState === 'euro') {
                isFormValid = true
              } else {
                // No errors and currency selected is not euro
                isFormValid = !!selectedCryptoAddress
              }

              if (selectedCurrencyState && !values.currencySelected) {
                setFieldValue('currencySelected', selectedCurrencyState)
              }

              return (
                <Form>
                  <Field name="currencySelected">
                    {({ field }) => (
                      <ComponentWrapper>
                        {/* prettier-ignore */}
                        <DoubleButtons column>
                              {acceptedCurrencies.internalReference.length % 2 === 0
                                ? acceptedCurrencies.internalReference.map(
                                    (currency, index) =>
                                      // Display current item and next only
                                      // (n + 1, which will be skipped next)
                                    index % 2 === 0 ? (
                                      <>
                                        <ButtonContainer key={currency}>
                                          <Button
                                            secondary={selectedCurrencyState && selectedCurrencyState !== acceptedCurrencies.internalReference[index]}
                                            disabled={acceptedCurrencies.internalReference[index]==='euro' && !user.countryObj?.mangopayAllowed}
                                          onClick={() => {
                                              setSelectedCurrencyState(
                                                acceptedCurrencies.internalReference[index],
                                              )
                                              setSelectedCurrency(
                                                acceptedCurrencies
                                                  .internalReference[index],
                                              )
                                              setFieldValue(
                                                field.name,
                                                acceptedCurrencies
                                                  .internalReference[index],
                                              )
                                          }}
                                          
                                          >
                                            {
                                              acceptedCurrencies
                                                .displayNames[index]
                                            }
                                        </Button>
                                        
                                          <Button
                                          secondary={selectedCurrencyState && selectedCurrencyState !== acceptedCurrencies.internalReference[index + 1]}
                                          disabled={acceptedCurrencies.internalReference[index+1] === 'euro' && !user.countryObj?.mangopayAllowed}
                                            
                                          onClick={() => {
                                              setSelectedCurrencyState(
                                                acceptedCurrencies
                                                  .internalReference[index + 1],
                                              )
                                              setSelectedCurrency(
                                                acceptedCurrencies
                                                  .internalReference[index + 1],
                                              )
                                              setFieldValue(
                                                field.name,
                                                acceptedCurrencies
                                                  .internalReference[index + 1],
                                              )
                                          }}
                                       
                                          >
                                            {
                                              acceptedCurrencies
                                                .displayNames[index + 1]
                                            }
                                        </Button>
                                        </ButtonContainer>
                                        {!user.countryObj?.mangopayAllowed && acceptedCurrencies.internalReference[index + 1] === 'euro' &&
                                          <ButtonContainer>
                                         <div style={{width:'50%', maxWidth:300}}></div>
                                          <Caption>
                                          Only available for European investors.
                                          </Caption>
                                          </ButtonContainer>
                                        }
                                        </>
                                      ) : (
                                        // Skip even number item what was already
                                        // displayed with n - 1
                                        <div key={currency} />
                                      ),
                                  )
                            : acceptedCurrencies.internalReference.map((currency, index) => (
                                  <>
                                  <ButtonContainer key={currency}>
                                    <Button
                                      secondary={selectedCurrencyState && selectedCurrencyState !== currency}
                                      disabled={currency==='euro' && !user.countryObj?.mangopayAllowed}

                                      onClick={() => {
                                        setSelectedCurrencyState(currency)
                                        setSelectedCurrency(currency)
                                        setFieldValue(
                                          field.name,
                                          currency,
                                        )
                                      }}
                                    >
                                      {acceptedCurrencies
                                                .displayNames[index]}
                                    </Button>
                                </ButtonContainer>
                                {!user.countryObj?.mangopayAllowed && currency === 'euro' &&
                                          <Caption center>
                                          Only available for European investors.
                                          </Caption>
                                        }
                                </>
                                ))}
                            </DoubleButtons>
                      </ComponentWrapper>
                    )}
                  </Field>
                  {/* <Tooltip
                    position="right"
                    title={'some title'}
                    theme="light"
                    arrow
                    duration={200}
                  >
                    some tooltip
                  </Tooltip> */}

                  {isAgreementRequired && (
                    <Fragment>
                      <ComponentWrapper>
                        <SectionTitle>IMPORTANT</SectionTitle>
                      </ComponentWrapper>

                      <Field name="agreementEther2">
                        {({ field }) => {
                          const currency = user.currencyName

                          return (
                            <ComponentWrapper noPaddingTop>
                              <AgreementItem
                                text={`You’ll need to pay in ${currency} from the same address as where you will receive your ${project.tokenSymbol} tokens. So make sure this address has enough funds.`}
                                checkbox={{
                                  id: field.name,
                                  text: 'I understand',
                                  checked: field.value,
                                  ...field,
                                  value: 'agreementEther2',
                                }}
                              />
                            </ComponentWrapper>
                          )
                        }}
                      </Field>
                    </Fragment>
                  )}

                  <Fragment>
                    {
                      <>
                        <ComponentWrapper>
                          <Text>
                            <p>{renderAddressSelectionMessage()}</p>
                          </Text>
                        </ComponentWrapper>

                        <ComponentWrapper borderBottom>
                          <SectionTitle>
                            {project.isLiquid
                              ? 'YOUR REGISTERED LIQUID ADDRESS'
                              : ''}
                          </SectionTitle>
                        </ComponentWrapper>
                        <Field name="cryptoAddress" disabled>
                          {({ field }) => {
                            return (
                              <Fragment>
                                {user.wallets
                                  .sort((a, b) => {
                                    if (user.isBMN1) {
                                      return (
                                        b.currencyBalanceBMN1 -
                                        a.currencyBalanceBMN1
                                      )
                                    } else return 0
                                  })
                                  .map((address, i) => {
                                    return (
                                      <CryptoAddress
                                        key={i}
                                        eqValueFontSize={13}
                                        title={address.name}
                                        address={address.address}
                                        status={
                                          address.isWhitelisted
                                            ? ''
                                            : 'not opted in'
                                        }
                                        data={
                                          selectedCurrencyState === 'bmn1'
                                            ? {
                                                value: fixDecimals(
                                                  address.currencyBalanceBMN1,
                                                  user.currencyDecimals,
                                                  Math.floor,
                                                ),
                                                unit: user.currencySymbol,
                                                eqValue: fixDecimals(
                                                  address.currencyBalanceBMN1inBTC,
                                                  8,
                                                ),
                                                eqUnit: 'BTC',
                                              }
                                            : {
                                                //address: address.address,
                                                value: fixDecimals(
                                                  address.balance,
                                                  project.tokenRoundingDecimals,
                                                ),
                                                eqValue: fixDecimals(
                                                  address.tokenBalanceinEURorUSD,
                                                  project.euroDecimals,
                                                ),
                                                eqUnit: `${project.denominationSymbol}`,
                                              }
                                        }
                                        info={
                                          selectedCurrencyState === 'bmn1'
                                            ? {
                                                value: `${
                                                  project.tokenSymbol
                                                } ${fixDecimals(
                                                  address.balance,
                                                  project.tokenRoundingDecimals,
                                                )}`,
                                                eqValue: fixDecimals(
                                                  address.tokenBalanceinEURorUSD,
                                                  project.euroDecimals,
                                                ),
                                                eqUnit:
                                                  project.denominationSymbol,
                                              }
                                            : {
                                                value: `${project.tokenSymbol}`,
                                              }
                                        }
                                        radio={{
                                          id: 'cryptoAddress' + i,
                                          name: field.name,
                                          value: address.address,
                                          address,
                                          onChange: field.onChange,
                                          onBlur: field.onBlur,
                                          disabled: !address.isWhitelisted,
                                          handleCheckedAddressRadio:
                                            setCheckedAddressRadio,
                                          handleSelectedCryptoAddress:
                                            setSelectedCryptoAddress,
                                          checkedAddressRadio,
                                        }}
                                        wrapped
                                        borderBottom
                                      />
                                    )
                                  })}
                              </Fragment>
                            )
                          }}
                        </Field>

                        <ComponentWrapper center></ComponentWrapper>
                      </>
                    }

                    <ComponentWrapper
                      noPaddingTop
                      style={{ marginBottom: '5rem' }}
                    >
                      <ConfirmContainer>
                        <Button type="submit" disabled={!isFormValid}>
                          Continue
                        </Button>
                        <ComponentWrapper
                          paddingVeticalHalf
                          noPaddingHorizontal
                          center
                        >
                          <FormError
                            show={
                              isAgreementRequired && !values.agreementEther2
                            }
                          >
                            Please mark the checkbox after the currency
                            selection to proceed
                          </FormError>
                        </ComponentWrapper>
                      </ConfirmContainer>
                    </ComponentWrapper>
                  </Fragment>
                </Form>
              )
            }}
          </Formik>
        </Column>
      </Row>
    </Layout>
  ) : (
    <StokrLoader />
  )
}

export default SelectAddress
