import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Project from './Project'
import { withRouter } from 'utils/customNavigate'
import { getCurrentProject } from 'utils/get-current-project'
import checkSaleTimeLeft from 'utils/check-sale-time-left'
import getMultiProgressBar from 'utils/multi-progress-bar'
import * as urls from 'constants/paths'

export const ProjectContext = React.createContext()

class ProjectProviderClass extends Component {
  state = {
    project: undefined,
    isFetchingProject: true,
  }

  componentDidMount() {
    const projectName = getCurrentProject()

    if (projectName) {
      this.setProject(projectName)
    } else {
      this.props.navigate('https://stokr-staging.de/404')
      this.setState({
        isFetchingProject: false,
      })
    }
  }

  setProject = async (projectName) => {
    try {
      const project = await Project.getProject(projectName)

      let company = this.setCompany(project.company)
      let blocks = this.setBlocks(project._id)
      const documents = this.setDocuments(project._id)

      project.blocks = await blocks
      project.company = await company
      project.documents = await documents

      const crowdsaleAddress = project.primaryAssetId
      if (!crowdsaleAddress) {
        this.props.navigate(urls.SALE_CLOSED)
      }

      let hardCap = project.greenCap + project.whiteCap
      project.whitePercentage = (project.whiteSold / project.whiteCap) * 100
      project.greenPercentage = (project.greenSold / project.greenCap) * 100
      project.softCapPercentage = (project.totalSoftCap / hardCap) * 100

      //days left
      let { daysLeft, daysLeftText } = checkSaleTimeLeft(project)
      if (!daysLeft || isNaN(daysLeft) || daysLeft < 0) {
        daysLeft = '-'
        if (daysLeftText !== 'FUNDING CLOSED') daysLeftText = 'DAYS UNTIL START'
      }

      project.daysLeft = daysLeft.toString()
      project.daysLeftText = daysLeftText
      project.isLiquid = project.token_issuance_type === 'liquid'
      project.multiProgressBar = getMultiProgressBar(project)
      project.euroDecimals = 2
      project.tokenRoundingDecimals = project.tokenDecimals

      const currenciesObj =
        project.blocks &&
        project.blocks.general &&
        project.blocks.general.find((item) => item.label === 'currencies')

      const acceptedCurrencies = currenciesObj
        ? JSON.parse(currenciesObj.body)
        : {}

      project.acceptedCurrencies = acceptedCurrencies
      this.setState({
        project,

        isFetchingProject: false,
      })
    } catch (error) {
      console.error(error)
      this.props.navigate(urls.OOPS, {
        state: {
          message: 'Can not reach the server. Please contact support@stokr.io',
        },
      })

      this.setState({
        isFetchingProject: false,
      })
    }
  }

  setBlocks = async (projectId) => {
    try {
      const { blocks } = await Project.getBlocks(projectId)

      const blocksParsed = {}

      blocks.forEach((block) => (blocksParsed[block.name] = block.content))

      return blocksParsed
    } catch (error) {
      throw error
    }
  }

  setCompany = async (companyId) => {
    try {
      const company = await Project.getCompany(companyId)

      return company
    } catch (error) {
      console.log(`Error getting company: ${error}`)

      throw error
    }
  }

  setDocuments = async (projectId) => {
    try {
      const documents = await Project.getDocuments(projectId)

      return documents
    } catch (error) {
      console.log(`Error getting documents: ${error}`)

      //if getting documents fails, it won't stop loading other parts
      // throw error
    }
  }

  render() {
    const { children } = this.props

    return (
      <ProjectContext.Provider
        value={{
          ...this.state,
        }}
      >
        {children}
      </ProjectContext.Provider>
    )
  }
}

ProjectProviderClass.propTypes = {
  children: PropTypes.node.isRequired,
}

export const ProjectProvider = withRouter(ProjectProviderClass)
export const ProjectConsumer = ProjectContext.Consumer
