import React, { useEffect, useState, useContext } from 'react'
import { useCustomNav } from 'utils/customNavigate'
import Layout from 'components/Layout/Layout'
import SEO from 'components/SEO/SEO'
import Text from 'components/Text/Text.styles'
import {
  Button,
  Background,
  Row,
  Column,
  ComponentWrapper,
  StokrLoader,
} from '@stokr/components-library'
import backgroundImage from 'static/images/covers/rocket.png'
import fixDecimals from 'utils/fix-decimals'
import { OrderContext } from 'context/Order/OrderContext'
import { UserContext } from 'context/User/UserContext'
import { ProjectContext } from 'context/Project/ProjectContext'

// if investment ID in url
// call DB find Investment
// get project name from URL
// show datsa from investment table not from props

const InvestSuccessPage = () => {
  const [isBTCPayRedirect, setIsBTCPayRedirect] = useState(false)
  const [currencyAmount, setCurrencyAmount] = useState(0)
  const [currencyType, setCurrencyType] = useState(0)
  const [tokenAmount, setTokenAmount] = useState(0)
  const [EURorUSDValue, setEURorUSDValue] = useState(0)
  const [EURorUSDValueForBtc, setEURorUSDValueForBtc] = useState(0)

  const [shouldRenderPage, setShouldRenderPage] = useState(false)
  const navigator = useCustomNav()

  const { order, getInvestment, updateInvestment, initializeOrder } =
    useContext(OrderContext)
  const { project } = useContext(ProjectContext)
  const { user } = useContext(UserContext)

  const currencyDic = {
    bitcoin: 'BTC',
    'lbtc-bfx': 'LBTC',
    'btc-bfx': 'BTC',
    tether: 'USDT',
    'lusdt-bfx': 'LUSDT',
  }

  const fetchInvestment = async (investmentId) => {
    let investment = getInvestment()

    const reqResponse = await investment
      .then(function (response) {
        for (var key in response.list) {
          if (response.list[key]._id == investmentId) {
            return response.list[key]
          }
        }
      })
      .then(undefined, function (error) {
        console.log('Failed!', error)
      })

    return reqResponse

    //investment.find(list => list._id === investmentId)
  }

  useEffect(() => {
    if (!order) {
      initializeOrder()
    }
    if (user.selectedAddress) {
      setShouldRenderPage(true)
      setCurrencyAmount(order.currencyValue)
      setCurrencyType(order.currencyType)
      setTokenAmount(order.tokenAmount)
    } else if (window.location.href.includes('?investmentId=')) {
      let investmentId = window.location.href
        .split('success?investmentId=')[1]
        .split('&')[0]

      async function fetchData() {
        const investment = await fetchInvestment(investmentId)
        setIsBTCPayRedirect(true)
        setCurrencyAmount(
          investment.secondaryCurrency
            ? investment.currencyAmount / investment.secondaryCurrencyinEURorUSD
            : investment.currencyAmount,
        )
        setCurrencyType(currencyDic[investment.currencyType])

        setTokenAmount(investment.tokenAmount)
        setEURorUSDValue(
          fixDecimals(
            tokenAmount * order?.tokenPrice +
              Number(investment?.feeInEURorUSD ?? 0),
            project.euroDecimals,
          ),
        )
        setEURorUSDValueForBtc(
          fixDecimals(
            tokenAmount * order?.tokenPrice +
              Number(investment?.feeInEURorUSD ?? 0),
            project.euroDecimals,
          ),
        )
        try {
          await updateInvestment(investmentId, 'Done')
          setShouldRenderPage(true)
        } catch (error) {
          console.error(error)
        }
      }
      fetchData()
    } else {
      navigator.navigate(`/${project.name}`)
    }
  })

  const orderToken = fixDecimals(tokenAmount, project.tokenRoundingDecimals)

  return shouldRenderPage ? (
    <Layout
      progress={{
        items: [
          { id: 'select-address', handleClick: () => {} },
          { id: 'order-tokens', handleClick: () => {} },
          { id: 'confirm', handleClick: () => {} },
        ],
        doneIndex: 2,
        activeIndex: 3,
      }}
      footerColor="red"
    >
      <SEO
        title={`Invest in ${project.name}`}
        keywords={['investment', 'success', 'STOKR']}
      />
      <Row main>
        <Column part={8}>
          <Background src={backgroundImage} mobileRatio="16:9" />
        </Column>

        <Column part={8} withBorder>
          <ComponentWrapper>
            {/* <SectionTitle>Invest in {project.tokenName}</SectionTitle> */}
          </ComponentWrapper>

          <ComponentWrapper noPaddingTop>
            <Text>
              <h3>SUCCESS! YOU HAVE INVESTED IN {project.name}</h3>
              {!isBTCPayRedirect ? (
                <p>
                  {`Your investment of ${
                    project.denominationSymbol
                  } ${EURorUSDValue} (${fixDecimals(
                    currencyAmount,
                    user.currencyDecimals,
                  )} ${user.currencySymbol}) is on its way to ${
                    project.title
                  } in exchange for ${orderToken} ${
                    project.tokenSymbol
                  } Securities. See the 'Overview' tab in your dashboard.`}
                </p>
              ) : (
                <p>{`Your investment of ${
                  project.denominationSymbol
                } ${EURorUSDValueForBtc} (${fixDecimals(
                  currencyAmount,
                  5,
                )} ${currencyType}) is on its way to ${
                  project.title
                } in exchange for ${tokenAmount} ${
                  project.tokenSymbol
                } Securities. See the 'Overview' tab in your dashboard.`}</p>
              )}
            </Text>
          </ComponentWrapper>

          <ComponentWrapper noPaddingTop>
            <Button
              minWidth="240px"
              onClick={() =>
                window.top.location.replace(
                  'https://dashboard.stokr-staging.de',
                )
              }
            >
              MY DASHBOARD
            </Button>
          </ComponentWrapper>
        </Column>
      </Row>
    </Layout>
  ) : (
    <StokrLoader />
  )
}

export default InvestSuccessPage
